header{
  display: flex;
}
header a {
  flex: 0 0 90%;
}
body {
  background-color: #fff2ea;
  font-family: sans-serif;
  text-align: center;
  margin:0;
  padding:0;
  
}

section.affirmation.normal{
  background-color: white;
  transition: all 1000ms linear;
}
section.affirmation{
  padding:2rem;
   border:2px solid black;
   margin:2rem;
   background-color: white;
}
section.affirmation.positive{
  background-color: green;
  transition: all 1000ms linear;
}
section.affirmation.negative{
 background-color: red;
 transition: all 1000ms linear;
}

p.stepWrapper {
  box-shadow: 2px 2px 2px silver;
  background-color: #fcf2bf;
  color: black;
  font-style: italic;
  padding: 0.2em;
  width: 100px;
  margin: 1em auto;
  border-radius: 0.5rem;
}

header{
  background-color: #126f87;
}
header a {
  display: flex;
  width: 100%;
  text-decoration: none;
}

header h1{
  padding:0.2rem 0 0 1.5rem;
  font-size: 1.8em;
  color: white;
}

.board,
.new-submition{
  animation: fadeIn 0.5s;
}

.new-submition .navigation {
  background-color: #fff2ea;
  margin-bottom: 0.1em;
}

.new-submition .biasSection{
  margin: 0 auto;
  text-align: left;
  width: 60%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

.new-submition .biasSection .enTankefelle{
  margin:0.5rem 0.5rem;
  padding:0.5rem;
  border: 0.125rem solid #188097;
  color: #126f87;
  display: flex;
  border-radius: 0.5rem;
}
.new-submition .biasSection .enTankefelle.true,
.new-submition .biasSection .enTankefelle:hover{
  background-color: #126f87;
  color:white;
}
.new-submition .biasSection .enTankefelle .tankefelleEksempel{
  font-style: italic;
  font-size: 0.8rem;
}
.new-submition .biasSection h4{
  text-align: center;
}
.new-submition div.submitionButtonsWrapper {
  padding-top:0;
}

textarea {
  background-color: #fff2ea;
}

.board {
  background-color: #fff2ea;
  padding: 0.5em;
}

div.entryView{
  background-color: white;
  padding: 0.3rem 1.5rem 0.3rem 1.5rem;
  border: 1px solid #188094;
  overflow-wrap: break-word;
  margin:0 auto 0.7rem auto;
  text-align: left;
  border-radius: 0.5em;
}
div.entryView p{
  white-space: pre-line;
}

input[type="range"],
input[type="date"]{
  margin: 2em 0 2em 0;
}
input[type="text"] {
  font-size: 1.5em;
}
.board input[type="text"]{
  border: 0.3rem solid #188097;
  border-radius: 0.5em;
}


input[type="button"] {
  background-color: yellowgreen;
  color: white;
  font-size: 1.5em;
  padding: 1em;
  border: none;
  box-shadow: 2px 2px 2px silver;
  border-radius: 1rem;
}

footer a {
  color:lightblue;
}
footer{
  background-color: #2b2c2b;
  color:white;
  padding:1.2rem;
  margin-top: 1.2rem;
}

a.button.innsikt{
  margin-right: 1rem;
}

a.button{
  display: inline-block;
  text-decoration: none;
  background-color: #188094;
  color: white;
  font-size: 1.5em;
  padding: 1em;
  margin-bottom: 0.3em;
  box-shadow: 2px 2px 2px silver;
  border: none;
  border-radius: 0.5em;
}
a.slettHendelse{
  margin-top: 0.5rem;
  float: right;
  border-radius: 0.5rem;
}
a.slettHendelse{
  padding:0.3rem;
  text-decoration: none;
}
a.slettHendelse:hover{
  color:white;  
  background-color: darkred;
}

input[type="button"]:hover,
input[type="submit"]:hover {
  cursor: pointer;
}

article{
  background-color: #e4f7f9;
  padding: 0 1.5rem 3rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  animation: fadeIn 0.5s; 
}
article .image_mind_cbt {
  background: url("./braing.webp");
  background-repeat: no-repeat;
  background-size: 200px 210px;
  height:220px;
  width:600px;
  margin: 6rem 2rem;
}

article section{
  text-align: left;
}
.board h1{
  margin-bottom: 0rem;
  padding-bottom: 1rem;
}
.board .søkwrapper{
  display: inline-block;
  padding-bottom: 1rem;
}
.board .søkwrapper .søkefelt{
  margin-right:1rem;
  font-size: 1em;
}
.board a.hendelseLink{
  text-decoration: none;
  color:black;
}
.board a.hendelseLink p{
  padding: 1rem;
  margin:0.5rem 0 0.5rem 0;
  background-color: white;
  border-bottom: 1px solid silver;
  border-radius: 0.4rem;
  text-align: left;
  text-decoration: none;
}
.board a.hendelseLink p:hover{
  background-color: #188094;
  color:white;
}
.board .button{
  padding:0.5rem;
}
.board .button.skrivehåndIkon{
  background: none;
  color:black;
}
.alternativTanke.enTankefelle,
.board .enTankefelle{
  border: 2px solid #188094;
  padding:0.3rem;
  color:#188094;
  margin-right: 0.2rem;
  line-height: 2.2rem;
  border-radius: 0.5rem;
}
.splash{
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width:734px;
}
/* Mobiler = en kolonne layout */
@media (max-width: 800px) {
  .board .søkwrapper .søkefelt{
    margin-right:0;
  }
  .board h1{
    margin-bottom: 0rem;
    padding-bottom: 0.4rem;
  }
  .board .button{
    padding:0.5rem;
    margin:0.5rem 0 0 1rem;
  }
  .new-submition .biasSection{
    text-align: left;
    width: 60%;
    margin:0 auto;
    padding: 0;
    display:inline;
  }

  .splash {
    flex-direction: column;
    width:100%;
  }
  article .image_mind_cbt {
    width: 196px;
    margin: 0 auto;
  }
}
/* Enda mindre mobiler */
@media (max-width: 300px){
  header h1{
    padding-bottom:0;
    font-size: 1em;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
